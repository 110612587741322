// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'
import Image from '@bodhi-project/components/lib/Image'
import teachers from '../images/homepage/teachers.png' 
// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import Copy from '../components/Copy'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Cambridge Teachers',
  nakedPageSlug: 'cambridge-teachers',
  pageAbstract: 'Content being added at the moment.',
}


const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <Copy>
      <h1>Cambridge Teachers</h1>
      <p>
          A well-trained faculty adorns the premises of the school where in they guide the students 
          in quite an innovative manner to be the thinkers, inquirers, communicators and responsible global 
          citizens. From time to time, the teachers attend workshops to facilitate their learning for 
          professional development. This enables them to share their creative transformation experiences with 
          others as well and to equip themselves better to mentor their students towards their holistic 
          development.
        </p>
        <i style={{ fontSize: '150%', color: '#black', fontWeight: 'bold' }}>
            Brigade of Warrior Teachers
          </i>
     
      <Image
            src={teachers}
            rawWidth={900}
            rawHeight={1000}
            style={{
              height: 'auto',
              width: '100%',
            
            }}
            alt="Cambridge Family"
            loader="gradient"
            gradientPreset="life"
          />
    </Copy>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
